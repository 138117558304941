import React, { Fragment, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./CSS/Global.css";
import MainSpinner from "./components/layout/Spinners/MainSpinner";
import Navbar from "./components/layout/Navbar";
import Alert from "./components/layout/Alert";
import PrivateRoute from "./components/routing/PrivateRoute";
// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import "./utils/setAxiosInterceptors";
import ScrollToTop from "./Scroll";
import Landing from "./components/layout/Landing";
import ErrorBoundary from "./components/Error/ErrorBoundary";
const Login = lazy(() => import("./components/auth/Login"));
const Register = lazy(() => import("./components/auth/Register"));
const RegisterFromLink = lazy(() =>
  import("./components/auth/RegisterFromLink")
);
const OrgCoachSub = lazy(() => import("./components/auth/subs/OrgCoachSub"));
const AthleteProfile = lazy(() =>
  import("./components/profile/athletes/AthleteProfile")
);
const ProfileSelect = lazy(() =>
  import("./components/profile/shared/ProfileSelect")
);
const Organisation = lazy(() =>
  import("./components/profile/organisations/Organisation")
);
const OrganisationProf = lazy(() =>
  import("./components/profiles/org-profiles/OrganisationProf")
);
const CoachProfile = lazy(() =>
  import("./components/profile/coaches/CoachProfile")
);
const Profile = lazy(() =>
  import("./components/profiles/ath-profiles/Profile")
);
const CoachesProfile = lazy(() =>
  import("./components/profiles/coach-profiles/CoachesProfile")
);
const PicksForm = lazy(() =>
  import("./components/profiles/coach-profiles/Coach-Picks/PicksForm")
);
const Groups = lazy(() => import("./components/Groups/Groups"));
const Group = lazy(() => import("./components/Groups/Group"));
const CoachWatchlist = lazy(() =>
  import("./components/profile/coaches/Following/CoachWatchlist")
);
// const Group = lazy(() => import('./components/Coach-Groups/Group'));
const Account = lazy(() => import("./components/Account/Account"));
const Subscription = lazy(() => import("./components/auth/subs/Subscription"));
const AdvancedSearch = lazy(() =>
  import("./components/profile/coaches/Search/AdvancedSearch")
);
const CoachSearch = lazy(() =>
  import("./components/profile/athletes/coach-search/CoachSearch")
);
const CreateProfile = lazy(() =>
  import("./components/profile-forms/Athletes/CreateProfile")
);
const CreateCoachProfile = lazy(() =>
  import("./components/profile-forms/Org_Coach/Coaches/CreateCoachProfile")
);
const CreateOrganisation = lazy(() =>
  import("./components/profile-forms/Org_Coach/Organisation/CreateOrganisation")
);
const SuccessSub = lazy(() => import("./components/auth/subs/SuccessSub"));
const Reset = lazy(() => import("./components/Reset-Password/Reset"));
const RequestReset = lazy(() =>
  import("./components/Reset-Password/RequestReset")
);
const Privacy = lazy(() => import("./components/Support_Pages/Privacy"));
const Terms = lazy(() => import("./components/Support_Pages/Terms"));
const Support = lazy(() => import("./components/Support_Pages/Support"));
const Timeline = lazy(() => import("./components/Timeline/Timeline"));
const PublicProfile = lazy(() =>
  import("./components/Public-Profile/PublicProfile")
);
const LinkPaid = lazy(() => import("./components/Public-Profile/LinkPaid"));
const SignupConfirmation = lazy(() =>
  import("./components/Promo-Content/Athletes/SignupConfirmation")
);
const MyEvents = lazy(() =>
  import("./components/profile/athletes/Events/MyEvents")
);

// Uncomment below to include serviceworker

if ("serviceWorker" in navigator) {
  if (!process.env.REACT_APP_DISABLE_SERVICE_WORKER) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/s-worker.js", { scope: "/" })
        .then((reg) => console.log("Success: ", reg.scope))
        .catch((err) => console.log("Failure: ", err));
    });
  }
}

window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  window.deferredPrompt = e;
  return false;
});

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <ErrorBoundary>
            <Navbar />
            <ScrollToTop />
            <Route exact path="/" component={Landing} />
            <section className="container">
              <Alert />

              <Switch>
                <Suspense fallback={<MainSpinner />}>
                  <Route exact path="/register" component={Register} />
                  <Route
                    exact
                    path="/register/promo/:code"
                    component={Register}
                  />
                  <Route
                    exact
                    path="/register-link/:sub/:email/:admin"
                    component={RegisterFromLink}
                  />
                  <Route
                    exact
                    path="/register-link/:sub/:email/:admin/sub-added"
                    component={OrgCoachSub}
                  />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/subscriptions" component={Subscription} />
                  <Route exact path="/reset" component={RequestReset} />
                  <Route exact path="/reset/:token" component={Reset} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/support" component={Support} />
                  <Route exact path="/terms" component={Terms} />
                  <Route
                    exact
                    path="/public/:userType/:id"
                    component={PublicProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/sub-success"
                    component={SuccessSub}
                  />
                  <PrivateRoute
                    exact
                    path="/create-profile"
                    component={CreateProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile"
                    component={AthleteProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/search"
                    component={CoachSearch}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/public-link/:id"
                    component={LinkPaid}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/my-events"
                    component={MyEvents}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/search/profile/:id"
                    component={CoachesProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/organisation/:id"
                    component={OrganisationProf}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/search/profile/:id/contact-form"
                    component={PicksForm}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/contact-form/:type/:promo/:id"
                    component={PicksForm}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/athletes/profile/:id"
                    component={Profile}
                  />
                  <PrivateRoute
                    exact
                    path="/AthleteProfile/event-confirmed/:code"
                    component={SignupConfirmation}
                  />
                  <PrivateRoute
                    exact
                    path="/create-coach-profile"
                    component={CreateCoachProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/create-organisation"
                    component={CreateOrganisation}
                  />
                  <PrivateRoute
                    exact
                    path="/organisation"
                    component={Organisation}
                  />
                  <PrivateRoute
                    exact
                    path="/organisation/athlete/profile/:id"
                    component={Profile}
                  />
                  <PrivateRoute
                    exact
                    path="/organisation/coaches/profile/:id"
                    component={CoachesProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/organisation/view-org/:id"
                    component={OrganisationProf}
                  />
                  <PrivateRoute
                    exact
                    path="/user-profiles"
                    component={ProfileSelect}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile"
                    component={CoachProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/search"
                    component={AdvancedSearch}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/search/profile/:id"
                    component={Profile}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/view-coach/profile/:id"
                    component={CoachesProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/organisation/:id"
                    component={OrganisationProf}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/watchlist"
                    component={CoachWatchlist}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/watchlist/profile/:id"
                    component={Profile}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/MyGroups/:id"
                    component={Group}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/MyGroups/:id/following/:id"
                    component={Profile}
                  />
                  <PrivateRoute
                    exact
                    path="/CoachProfile/MyGroups/:id/members/:id"
                    component={CoachesProfile}
                  />
                  <PrivateRoute exact path="/account" component={Account} />
                  <PrivateRoute
                    exact
                    path="/timeline/:id"
                    component={Timeline}
                  />
                  <PrivateRoute exact path="/groups" component={Groups} />
                  <PrivateRoute exact path="/groups/:id" component={Group} />
                </Suspense>
              </Switch>
            </section>
          </ErrorBoundary>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
