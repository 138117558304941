import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  USER_UPDATED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
  CLEAR_COACH_PROFILE,
  CLEAR_ORGANISATION,
  RESET_DATA,
} from "./types";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register =
  ({
    name,
    email,
    birthDate,
    caregiverEmail,
    phone,
    country,
    city,
    state,
    gender,
    password,
    userType,
    organisation,
    orgAdmin,
    activeProfile,
    team,
    role,
    agreedToTerms,
    sportsArray,
    orgDetails,
    subscriptions,
    registerLink,
    eligibleCountries,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name,
      email,
      birthDate,
      caregiverEmail,
      phone,
      country,
      city,
      state,
      gender,
      password,
      userType,
      organisation,
      orgAdmin,
      activeProfile,
      team,
      role,
      agreedToTerms,
      sportsArray,
      orgDetails,
      subscriptions,
      registerLink,
      eligibleCountries,
    });

    try {
      const res = await axios.post("/api/users", body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Check if user exists with this email
export const checkEmail = async (email) => {
  try {
    const res = await axios.post("/api/users/check", email);
    return res;
  } catch (err) {
    console.error(err);
  }
};

// Update User
export const updateUser = (formData, type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.put(
      "/api/users/update",
      { formData, type },
      config
    );
    dispatch({
      type: USER_UPDATED,
      payload: res.data,
    });
    dispatch(loadUser());
    dispatch(setAlert("Account Updated!", "success"));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Get user subscription status
export const getSubStatus = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/subs/subscription-status");
    dispatch({
      type: USER_UPDATED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Logout / Clear Profile
export const logout = (userType) => async (dispatch) => {
  const deleteFromCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          if (!name.includes("Static")) {
            caches.delete(name);
          }
        });
      });
    }
  };

  if (userType === 1) {
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: LOGOUT });
    dispatch({ type: RESET_DATA });
  } else {
    dispatch({ type: CLEAR_COACH_PROFILE });
    dispatch({ type: LOGOUT });
    dispatch({ type: RESET_DATA });
  }
  await deleteFromCache();
  await window.location.reload();
};

// Delete Account + Profile
export const deleteAccount = (formData) => async (dispatch) => {
  const { userType, profile } = formData;
  const deleteFromCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          if (!name.includes("Static")) {
            caches.delete(name);
          }
        });
      });
    }
  };

  try {
    const res = await axios.put("/api/users/delete", formData);

    if (profile === "org") {
      dispatch(setAlert("Organisation Deleted!", "success"));
      dispatch({ type: CLEAR_ORGANISATION });
      dispatch(loadUser());
    } else {
      dispatch(setAlert("Account Deleted!", "success"));
      // dispatch(logout());
      if (userType === 1) {
        dispatch({ type: CLEAR_PROFILE });
        dispatch({ type: LOGOUT });
        dispatch({ type: RESET_DATA });
      } else {
        dispatch({ type: CLEAR_COACH_PROFILE });
        dispatch({ type: LOGOUT });
        dispatch({ type: RESET_DATA });
      }
      await deleteFromCache();
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
