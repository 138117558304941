import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Retrieve token from storage
    if (token) {
      config.headers["x-auth-token"] = token; // Attach token to request
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access, e.g., redirect to login or clear token
      console.error("Unauthorized! Redirecting to login...");
      localStorage.removeItem("token"); // Clear stored token

      if (window.location.pathname !== "/login") {
        window.location.href = "/login"; // Redirect to login page
      }
    }
    return Promise.reject(error);
  }
);
